
import Vue from 'vue';
import Router from 'vue-router'
import managerNavH5 from './managerNavH5/index.vue';
// import i18n from '../../src/utils/i18n'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/managerNavH5/managerNavH5.html',
      name: 'managerNavH5',
      component: managerNavH5,
      // meta: {
      //   title: i18n.t('managerNavigation')
      // }
    },
    {
      path: '/managerNavH5',
      name: 'managerNavH5',
      component: managerNavH5,
      // meta: {
      //   title: i18n.t('managerNavigation')
      // }
    }
  ]
});
