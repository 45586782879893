<template>
  <div id="appbos">
    <h5Component />
  </div>
</template>
<script>
// 添加请求拦截器


  const h5Component = async () => {
    if (window.h5_bos) {
      // 当前的安装工具
      const factoryIndex = await window.h5_bos.get('index');
      // 当前的安装工具
      return factoryIndex();
    }
  };
  h5Component();

  export default {
    data() {
      return {
          
      };
    },
    components: {
      h5Component
    }
  };
</script>

