// JS HMACSHA256加密

function getWebtoken() {
    var jwt = require('jsonwebtoken');
    // let timeValue = Math.floor(Date.now() / 1000);
    let timeValue = getServerTime();
    let data = jwt.sign({
        exp: timeValue + (30 * 60),
        nbf: timeValue,
        iat: timeValue,
    }, 'Z3g5WFRlRzFXbkx1U2t5Q251azh0dz09');
    return data
}

function setTokenkey() {
    // let timeValue = Math.floor(Date.now() / 1000);
    let timeValue = getServerTime();
    window.localStorage.setItem('jsonwebtokenkey', JSON.stringify({
        time: timeValue + (30 * 60),
        jws: getWebtoken()
    }))
    return getWebtoken()
}
//  权限
export const Authentication = () => {
    let jsonwebtokenkey = window.localStorage.getItem('jsonwebtokenkey')
    if (jsonwebtokenkey && JSON.parse(jsonwebtokenkey)) {
        if (JSON.parse(jsonwebtokenkey).time -  getServerTime()< 10 * 60 ) {
            return setTokenkey()
        } else {
            return JSON.parse(jsonwebtokenkey).jws
        }

    } else {
        return setTokenkey()
    }

}
// 获取服务器时间

function getServerTime() {
    // var xmlhttp = null;
    // if (window.ActiveXObject) xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
    // else xmlhttp = new XMLHttpRequest();
    // xmlhttp.open('post', "/p/c/u_note/getMsgCnt", false);
    // xmlhttp.setRequestHeader('If-Modified-Since', 'q');
    // xmlhttp.send();
    // var timestr = xmlhttp.getResponseHeader('Date');
    // if(!timestr){
    //      return Math.floor(Date.now() / 1000)- (2 * 60)
    // }else{
    //     return Math.floor(new Date(timestr).getTime() / 1000)- (2 * 60)
    // }
    return Math.floor(Date.now() / 1000)- (2 * 60)
 }