import Vue from 'vue';
import App from './AppH5.vue'
import router from './router.config';
// import i18n from '../../src/utils/i18n'
import { Authentication } from './JWT'

//customertype
function hookAJAX() {
  let parms = getQueryString();
  XMLHttpRequest.prototype.nativeOpen = XMLHttpRequest.prototype.open
  var customizeOpen = function (method, url, async, user, password) {
    this.nativeOpen(method, url, async, user, password)    
    if(parms.customertype){
      this.setRequestHeader('customertype', parms.customertype)
    }
    if(/api/.test(url)){
      this.setRequestHeader(
        'jws',Authentication(url)
      )
    }
  }

  XMLHttpRequest.prototype.open = customizeOpen

}

hookAJAX();





function getQueryString() {  
  var qs = location.search.substr(1), // 获取url中"?"符后的字串  
    args = {}, // 保存参数数据的对象
    items = qs.length ? qs.split("&") : [], // 取得每一个参数项,
    item = null,
    len = items.length;
 
  for(var i = 0; i < len; i++) {
    item = items[i].split("=");
    var name = decodeURIComponent(item[0]),
      value = decodeURIComponent(item[1]);
    if(name) {
      args[name] = value;
    }
  }
  return args;
}
// url 拦截
window.$BackUrl = getQueryString('url').url

axios.interceptors.request.use( async function (config) {
  // 打印出请求的完整 URL
  // 在这里可以修改请求地址或其他配置
  // 例如，添加认证信息到请求头
  if(window.$BackUrl){
    config.url = window.$BackUrl + config.url

  }
  
  // 返回修改后的请求配置
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

new Vue({
    el: '#app',
    router,
    // i18n,
    components: { App },
    template: '<App/>'
  })